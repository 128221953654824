const PrivacyData = () => {
  return (
    <>
      <div class="terms-container">
        <h1 class="terms-title">Privacy Policy</h1>
        <p class="terms-intro">
          This Privacy Policy describes the policies and procedures of
          ServerHub9 on the collection, use, and disclosure of Your information
          when You use the Service. It also outlines Your privacy rights and how
          the law protects You.
        </p>
        <p>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </p>
        <section class="terms-section">
          <h2>1. Interpretation and Definitions</h2>

          <h3>1.1 Interpretation</h3>
          <p>
            Words with initial capital letters have meanings defined in this
            Privacy Policy. These definitions apply whether they appear in
            singular or plural forms.
          </p>

          <h3>1.2 Definitions</h3>
          <p>For the purposes of this Privacy Policy:</p>
          <ul class="terms-list">
            <li>
              <strong>Account</strong>: A unique account created for You to
              access our Service or parts of our Service.
            </li>
            <li>
              <strong>Company</strong>: Refers to ServerHub9, the entity
              providing the Service.
            </li>
            <li>
              <strong>Cookies</strong>: Small files placed on Your device by a
              website, containing details of Your browsing history.
            </li>
            <li>
              <strong>Personal Data</strong>: Any information relating to an
              identified or identifiable individual.
            </li>
            <li>
              <strong>Service</strong>: Refers to the website and related
              offerings provided by ServerHub9.
            </li>
            <li>
              <strong>Usage Data</strong>: Automatically collected data
              generated by the use of the Service or from the Service
              infrastructure itself.
            </li>
          </ul>
        </section>
        <section class="terms-section">
          <h2>2. Collecting and Using Your Personal Data</h2>

          <h3>2.1 Types of Data Collected</h3>
          <p>
            <strong>Personal Data:</strong> While using Our Service, We may ask
            You to provide certain information, including but not limited to:
          </p>
          <ul class="terms-list">
            <li>Email address</li>
            <li>First and last name</li>
            <li>Phone number</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
          </ul>
          <p>
            <strong>Usage Data:</strong> Usage Data is collected automatically
            when using the Service. It may include information such as Your
            device's IP address, browser type, browser version, pages of Our
            Service that You visit, and the time and date of Your visit.
          </p>

          <h3>2.2 Use of Your Personal Data</h3>
          <p>ServerHub9 may use Personal Data for the following purposes:</p>
          <ul>
            <li>To provide and maintain the Service</li>
            <li>To manage Your Account</li>
            <li>
              To contact You with newsletters, promotional materials, and other
              information
            </li>
            <li>To manage Your requests</li>
          </ul>
        </section>
        <section class="terms-section">
          <h2>3. Sharing Your Personal Data</h2>
          <p>
            We may share Your Personal Data with trusted third parties in
            certain circumstances:
          </p>
          <ul>
            <li>
              With service providers who assist us in operating our Service
            </li>
            <li>
              To comply with legal obligations, enforce our agreements, or
              protect our rights
            </li>
            <li>
              In connection with the sale or transfer of assets or business
            </li>
          </ul>

          <h3>3.1 Third-Party Services</h3>
          <p>
            We may employ third-party companies and individuals to facilitate
            our Service, provide the Service on our behalf, or assist us in
            analyzing how our Service is used. These third parties have access
            to Your Personal Data only to perform specific tasks on our behalf
            and are obligated not to disclose or use it for any other purpose.
          </p>
        </section>
        <section class="terms-section">
          <h2>4. Data Security</h2>
          <p>
            We take the security of Your Personal Data seriously and implement
            reasonable security measures to protect it. However, no method of
            transmission over the internet or method of electronic storage is
            100% secure, and we cannot guarantee absolute security.
          </p>
        </section>
        <section class="terms-section">
          <h2>5. Your Privacy Rights</h2>
          <p>
            Depending on Your location, You may have the right to access,
            correct, or delete Your Personal Data. You may also have the right
            to withdraw consent, object to processing, or request data
            portability. Please contact us for more information regarding your
            privacy rights.
          </p>
        </section>
        <section class="terms-section">
          <h2>6. Changes to This Privacy Policy</h2>
          <p>
            We may update our Privacy Policy from time to time. Any changes will
            be posted on this page, and the "Last updated" date at the top of
            the policy will be revised accordingly. You are advised to review
            this Privacy Policy periodically for any updates or changes.
          </p>
        </section>
        <section class="terms-section">
          <h2>7. Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at:
          </p>
          <ul>
            <li>Email: serverhub9.info@gmail.com</li>
          </ul>
        </section>
      </div>
    </>
  );
};
export default PrivacyData;
