export const baseURL = "https://server-hub-backend.caprover.musuvillc.com/";
// export const baseURL="https://hub-be.caprover-demo.mtechub.com/";
// export const baseURL = "http://localhost:5020/";

export const portal_url = "https://portal.serverhub9.co/";
// export const portal_url = "http://localhost:3001/";
// export const website_url = "http://localhost:3000/";
export const website_url = "https://serverhub9.co/";

// export const portal_url="https://serverhub-portal.netlify.app/"
