const TermsData = () => {
  return (
    <>
      <div class="terms-container">
        <h1 class="terms-title">Terms and Conditions</h1>
        <p class="terms-intro">
          Welcome to Our Service! Please read these terms and conditions
          carefully before using Our Service. By accessing or using the Service,
          You agree to be bound by these Terms and Conditions. If You disagree
          with any part of these Terms, You may not access the Service.
        </p>

        <section class="terms-section">
          <h2>1. Interpretation and Definitions</h2>

          <h3>1.1 Interpretation</h3>
          <p>
            Words with capitalized initial letters have meanings defined under
            the following conditions. These definitions apply regardless of
            singular or plural forms.
          </p>

          <h3>1.2 Definitions</h3>
          <p>For the purposes of these Terms and Conditions:</p>
          <ul class="terms-list">
            <li>
              <strong>Affiliate:</strong> An entity that controls, is controlled
              by, or is under common control with a party, where "control" means
              ownership of 50% or more of the shares, equity interest, or other
              securities entitled to vote for election of directors or other
              managing authority.
            </li>
            <li>
              <strong>Country:</strong> Refers to Virginia, United States.
            </li>
            <li>
              <strong>Company:</strong> (referred to as "We," "Us," or "Our" in
              this Agreement) refers to serverhub9, located at serverhub9.
            </li>
            <li>
              <strong>Device:</strong> Any device capable of accessing the
              Service, such as a computer, a cellphone, or a digital tablet.
            </li>
            <li>
              <strong>Service:</strong> Refers to the Website, Application, or
              other platform operated by the Company.
            </li>
            <li>
              <strong>Terms and Conditions:</strong> (also referred to as
              "Terms") mean these Terms and Conditions that form the entire
              agreement between You and the Company regarding the use of the
              Service.
            </li>
            <li>
              <strong>Third-Party Social Media Service:</strong> Any services or
              content (including data, information, products, or services)
              provided by a third party that may be displayed, included, or made
              available by the Service.
            </li>
            <li>
              <strong>You:</strong> The individual accessing or using the
              Service, or the company or other legal entity on behalf of which
              such individual is accessing or using the Service.
            </li>
          </ul>
        </section>

        <section class="terms-section">
          <h2>2. Acknowledgment</h2>
          <p>
            These are the Terms and Conditions governing the use of this Service
            and the agreement between You and the Company. These Terms and
            Conditions set out the rights and obligations of all users regarding
            the use of the Service.
          </p>
          <p>
            Your access to and use of the Service is conditioned on Your
            acceptance of and compliance with these Terms. These Terms apply to
            all visitors, users, and others who access or use the Service.
          </p>
          <p>
            You represent that you are over the age of 18. The Company does not
            permit those under 18 to use the Service.
          </p>
        </section>

        <section class="terms-section">
          <h2>3. Use of the Service</h2>
          <h3>3.1 User Responsibilities</h3>
          <p>
            You agree to use the Service only for lawful purposes and in a way
            that does not infringe the rights of or restrict the use and
            enjoyment of the Service by any other party.
          </p>

          <h3>3.2 Prohibited Uses</h3>
          <p>You may not use the Service to:</p>
          <ul class="terms-list">
            <li>Engage in unlawful or fraudulent activities.</li>
            <li>Transmit or distribute viruses or harmful software.</li>
            <li>
              Infringe the intellectual property rights of the Company or
              others.
            </li>
            <li>Collect or store personal data about other users.</li>
          </ul>
        </section>

        <section class="terms-section">
          <h2>4. Intellectual Property</h2>
          <p>
            The Service and its original content, features, and functionality
            are and will remain the exclusive property of the Company and its
            licensors. You are granted a limited, non-exclusive,
            non-transferable license to use the Service for personal or
            commercial purposes, subject to these Terms.
          </p>
        </section>

        <section class="terms-section">
          <h2>5. Limitation of Liability</h2>
          <p>
            To the maximum extent permitted by applicable law, in no event shall
            the Company or its suppliers be liable for any indirect, incidental,
            special, consequential, or punitive damages, including without
            limitation loss of profits, data, use, goodwill, or other intangible
            losses, resulting from 
            <ul class="terms-list">
            <li>Your use or inability to use the Service.</li>
            <li>unauthorized access to or use of Your data. </li>
             <li>any third-party conduct. </li> 
            </ul>
          </p>
        </section>

        <section class="terms-section">
          <h2>6. Termination</h2>
          <p>
            We may terminate or suspend Your access immediately, without prior
            notice or liability, for any reason, including if You breach these
            Terms.
          </p>
          <p>
            Upon termination, Your right to use the Service will cease
            immediately.
          </p>
        </section>

        <section class="terms-section">
          <h2>7. Governing Law</h2>
          <p>
            These Terms shall be governed and construed in accordance with the
            laws of the Country, without regard to its conflict of law
            provisions.
          </p>
        </section>

        <section class="terms-section">
          <h2>8. Changes to These Terms</h2>
          <p>
            We reserve the right to modify or replace these Terms at any time.
            If a revision is material, We will provide notice prior to any new
            terms taking effect. By continuing to access or use Our Service
            after revisions become effective, You agree to be bound by the
            updated Terms.
          </p>
        </section>
        <section class="terms-section">
          <h2>9. No Refund Policy</h2>
          <p>
            All sales made through Our Service are final and non-refundable. By
            completing a purchase, You acknowledge and agree that We do not
            offer refunds, exchanges, or returns for any products or services
            provided unless otherwise required by applicable law.
          </p>
          <p>
            If You experience any issues with Your purchase, please contact Our
            support team for assistance. While We strive to ensure customer
            satisfaction, refunds are not guaranteed under any circumstances.
          </p>
        </section>
        <section class="terms-section">
          <h2>10. Contact Us</h2>
          <p>
            If You have any questions about these Terms and Conditions, You can
            contact us at:
          </p>
          <ul class="terms-list">
            <li>Email: serverhub9.info@gmail.com</li>
          </ul>
        </section>
      </div>
    </>
  );
};
export default TermsData;
